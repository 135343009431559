
import { Component, Vue } from 'vue-property-decorator'
import { Info } from '../../types/potion'
import { FileInfo } from '@/types/common'

@Component({
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class PotionDetail extends Vue {
  private tabList = ['产品特性', '用法用量', '施药方法', '注意事项']
  private tabIndex = '0'
  private info: Info | {} = {}
  get medicineId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.medicineId) {
      this.getDetail()
    }
  }

  getDetail () {
    this.$axios.get(this.$apis.potion.detailDataMedicine, {
      medicineId: this.medicineId
    }).then(res => {
      this.info = res
    })
  }
}
